<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md mb-4">
            <h3 class="fw-bold text-success mb-0">Booking Payments</h3>
          </div>
          <div class="col-2 mb-4">
            <select
              v-model="type"
              class="form-control me-2"
            >
              <option value="stripe">Stripe</option>
              <option value="cash">Cash</option>
              <option value="bacs">BACS</option>
              <option value="pdq_uk">PDQ - UK</option>
              <option value="pdq_malta">PDF - Malta</option>
            </select>
          </div>
          <div class="col-2 mb-4">
            <select
              v-model="retreat"
              class="form-control me-2"
            >
              <option value="">All retreats</option>
              <option value="uk">UK</option>
              <option value="malta">Malta</option>
            </select>
          </div>
          <div class="col-auto mb-4 ms-auto">
            <date-picker
              ref="datePicker"
              v-model="range"
              is-range
              :popover="{ visibility: 'click', transition: 'fade' }"
              :input-props="{ class: 'form-control form-control-sm' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                  <input
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on:click="togglePopover"
                    class="d-inline form-control disabled"
                    placeholder="End Date"
                    readonly
                  />
                </div> </template
            ></date-picker>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table" v-if="!busy">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Client name</th>
                <th scope="col">Method</th>
                <th scope="col">Total</th>
                <th v-if="type == 'stripe'" scope="col">Stripe ref</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="t in transactions" :key="t.id">
                <td>
                  {{ t.created_at | formatDate }}
                </td>
                <td>
                  {{ t.client ? t.client.name : "-" }}
                </td>
                <td>
                  {{ t.type | typeFilter }}
                </td>
                <td>
                  {{ t.total.toFixed(2) | numberWithCommas }}
                </td>
                <td v-if="type == 'stripe'">
                  {{ t.stripe_charge_id ? t.stripe_charge_id : t.stripe_payment_intent_id }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <loading v-if="busy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      busy: false,
      transactions: [],
      popover: {
        visibility: "focus"
      },
      range: {
        start: this.$route.query.start ? this.$route.query.start : moment().subtract(1, 'months').format("YYYY-MM-DD"),
        end: this.$route.query.end ? this.$route.query.end : new Date()
      },
      retreat: "",
      type: "stripe"
    };
  },
  methods: {
    fetchBookingPayments() {
      this.transactions = [];
      this.busy = true;
      this.$axios(process.env.VUE_APP_API_URL + this.$route.fullPath
        + "?start=" + moment(this.range.start).format("YYYY-MM-DD")
        +  "&end=" + moment(this.range.end).format("YYYY-MM-DD")
        + "&retreat=" + this.retreat + "&type=" + this.type)
        .then(({ data }) => {
          this.transactions = data;
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
  watch: {
    range: {
      handler: function(val, old) {
        this.fetchBookingPayments();
      }
    },
    retreat: {
      handler: function(val, old) {
        this.fetchBookingPayments();
      }
    },
    type: {
      handler: function(val, old) {
        this.fetchBookingPayments();
      }
    }
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchBookingPayments();
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("LL");
      }
      return "Not Set";
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    typeFilter(value) {
      if (!value) return "";

      if (value != "stripe" && value != "cash") {
        return value.replace("_", " - ").toUpperCase();
      } else {
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
  },
  components: {
    DatePicker,
    Loading
  }
};
</script>

<style>
</style>